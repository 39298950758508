
$(document).ready(function () {

  let images = gsap.utils.toArray(".healthcare img"),
    tl = gsap.timeline({ repeat: -1 }),
    fadeDuration = 2,
    stayDuration = 4;

  // show the first one
  gsap.set(images[0], { autoAlpha: 1 });

  // fade each one in successively (in a staggered fashion) EXCEPT the first one (because it's already visible)
  tl.to(images.slice(1), { delay: stayDuration, autoAlpha: 1, duration: fadeDuration, stagger: stayDuration + fadeDuration })
    // hide each one after the next one finishes fading in on top of it. Exclude the final image because we'll handle the crossfade with the first image with a tween at the end.
    .to(images.slice(0, images.length - 1), { autoAlpha: 0, duration: 0.01, stagger: stayDuration + fadeDuration }, stayDuration + fadeDuration)
    // show the first image (but it won't be visible yet because the last image is on top of it)
    .set(images[0], { autoAlpha: 1 })
    // now fade out the last image so that the first one is showing again
    .to(images[images.length - 1], { autoAlpha: 0, duration: fadeDuration }, "+=" + stayDuration);


  let mobileImages = gsap.utils.toArray(".healthcare-mobile img"),
    tlmobile = gsap.timeline({ repeat: -1 });

    // show the first one
    gsap.set(mobileImages[0], { autoAlpha: 1 });

  // fade each one in successively (in a staggered fashion) EXCEPT the first one (because it's already visible)
  tlmobile.to(mobileImages.slice(1), { delay: stayDuration, autoAlpha: 1, duration: fadeDuration, stagger: stayDuration + fadeDuration })
    // hide each one after the next one finishes fading in on top of it. Exclude the final image because we'll handle the crossfade with the first image with a tween at the end.
    .to(mobileImages.slice(0, mobileImages.length - 1), { autoAlpha: 0, duration: 0.01, stagger: stayDuration + fadeDuration }, stayDuration + fadeDuration)
    // show the first image (but it won't be visible yet because the last image is on top of it)
    .set(mobileImages[0], { autoAlpha: 1 })
    // now fade out the last image so that the first one is showing again
    .to(mobileImages[images.length - 1], { autoAlpha: 0, duration: fadeDuration }, "+=" + stayDuration);

  // ScrollTrigger.create({
  //     trigger: ".healthcare-trigger",
  //     pin: ".pin",
  //     start: 'top top',
  //     end: 'bottom bottom',
  //     markers: false
  //   });

  //   let headings = gsap.utils.toArray(".healthcare");
  //   gsap.set(headings, { opacity: 0 })
  //   gsap.set(headings[0], { opacity: 1 })

  //   headings.forEach((heading, i) => {
  //     let tl = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: ".healthcare-trigger",
  //         start: () => `top+=${i * window.innerHeight} top`,
  //         end: () => `top+=${(i + 2) * window.innerHeight} top`,
  //         markers: false,
  //         scrub: true
  //       }
  //     });



  //     if (i === headings.length - 1) {
  //       tl.to(heading, { opacity: 1 })
  //         .to(heading, { opacity: 1 })
  //     } else {
  //       tl.to(heading, { opacity: 1, ease: "power4.out" })
  //         .to(heading, { opacity: 0, ease: "power4.in" })
  //     }

  //   });
});